import { BookInfoType } from "../types/Book"

export const setBookInfo =  (url: string, data: BookInfoType)=> {
    if(!data.chapter) return alert("Chapter not found!")
    if(!data.width) return alert("Screen width not found!")
    if(!data.height) return alert("Screen height not found!")
    localStorage.setItem(url, JSON.stringify(data))
}

export const getBookInfo = (url: string | null): BookInfoType | null => {
    if(!url) return null
    const record = localStorage.getItem(url)
    if(record) return JSON.parse(record)
    return null
}

export const removeBookInfo =  (url: string)=> {
    localStorage.removeItem(url)
}