import React, { useEffect, useState, useRef, useMemo } from "react";
import Epub, { Book, Rendition } from "epubjs";
import { useSearchParams } from "react-router-dom";
import { ChapterType } from "../../types/Book.ts";
import { getBookInfo, setBookInfo } from "../../utils/storage.ts";

const EpubView: React.FC = () => {
  const [searchParam] = useSearchParams();
  const [isLoaded, setIsLoaded] = useState(false);
  const viewerRef = useRef<HTMLDivElement>(null);
  const [book, setBook] = useState<Book | null>(null);
  const [rendition, setRendition] = useState<Rendition | null>(null);
  const [initLoading, setInitLoading] = useState(false);

  const path = useMemo(() => searchParam.get("_path"), [searchParam]);
  const width = useMemo(() => searchParam.get("width"), [searchParam]);
  const height = useMemo(() => searchParam.get("height"), [searchParam]);
  const bookInfo = useMemo(() => {
    if (!initLoading) return getBookInfo(`${path}-${width}-${height}`);
    return null;
  }, [initLoading, path, height, width]);

  useEffect(() => {
    if (!path) return alert("Path or viewer not found");

    const initBook = async () => {
      if (viewerRef.current) {
        if (book) {
          book.destroy();
        }

        try {
          const newBook = Epub(path, {});
          let count = 1;
          let chapArray: ChapterType[] = [];
          let isInit = Boolean(bookInfo) ? true : false;
          if (!isInit) setInitLoading(true);
          setBook(newBook);
          await newBook.ready;
          await (newBook.locations as any).generate();

          const newRendition = newBook.renderTo(viewerRef.current, {
            width: width ?? "100%",
            height: height ?? "100%",
            flow: "paginated",
          });
          setRendition(newRendition);
          newRendition.on("relocated", (loc) => {
            if (!isInit) {
              chapArray.push({ epubfic: loc.start.cfi, location: count });
              newRendition.next();
              count++;
              if (newRendition?.location?.atEnd) {
                isInit = true;
                setInitLoading(false);
                setBookInfo(`${path}-${width}-${height}`, {
                  chapter: chapArray,
                  width: width,
                  height: height,
                });
                newRendition.display();
              }
            }
          });
          newRendition.display();

          setIsLoaded(true);
        } catch (error) {
          console.error("Error initializing book:", error);
          alert("Error initializing book:");
        }
      } else {
        (window as any)?.ReactNativeWebView?.postMessage(
          JSON.stringify({ type: "debug", message: "Viewer not found" })
        );
      }
    };
    initBook();
    return () => {
      if (book) {
        book.destroy();
      }
    };
  }, []); // 'book', 'bookInfo', 'height', 'path', and 'width' never change on first render

  useEffect(() => {
    try {
      (window as any)?.ReactNativeWebView?.postMessage(
        JSON.stringify({ type: "TotalPage", size: bookInfo?.chapter?.length })
      );
    } catch (error) {
      alert("Error on total page");
    }
  }, [bookInfo?.chapter?.length]);

  useEffect(() => {
    if (!rendition) return;
    try {
      window.addEventListener("message", (event) => {
        if (event?.data?.isCustom) {
          const parseEvent = event?.data || null;
          if (!parseEvent) return;
          switch (parseEvent?.type) {
            case "TURN_PAGE_RIGHT": {
              (window as any)?.ReactNativeWebView?.postMessage(
                JSON.stringify({
                  type: "debug",
                  message: JSON.stringify(event?.data),
                })
              );
              rendition.next();
              break;
            }
            case "TURN_PAGE_LEFT": {
              (window as any)?.ReactNativeWebView?.postMessage(
                JSON.stringify({
                  type: "debug",
                  message: JSON.stringify(event?.data),
                })
              );
              rendition.prev();
              break;
            }
          }
        }
      });
    } catch (error) {
      alert("Error on receive message");
    }

    return () => window.removeEventListener("message", () => {});
  }, [rendition]);

  return (
    <div
      style={{
        position: "relative",
        height: "100%",
        width: "100%",
        userSelect: "none",
        WebkitUserSelect: "none",
        MozUserSelect: "none",
        msUserSelect: "none",
      }}
    >
      {initLoading && (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            backgroundColor: "#FFFFFF",
            opacity: 1,
            position: "fixed",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p>LOADING....</p>
        </div>
      )}
      <div ref={viewerRef} style={{ height: "100%" }} />
      {!isLoaded && (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            backgroundColor: "#FFFFFF",
            opacity: 1,
            position: "fixed",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p>Init.....</p>
        </div>
      )}
    </div>
  );
};

export default EpubView;
